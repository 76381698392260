// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookiesPolicy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-termsofservice-js": () => import("./../../../src/pages/termsofservice.js" /* webpackChunkName: "component---src-pages-termsofservice-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/categoryPage.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-game-landing-page-js": () => import("./../../../src/templates/gameLandingPage.js" /* webpackChunkName: "component---src-templates-game-landing-page-js" */),
  "component---src-templates-game-page-js": () => import("./../../../src/templates/gamePage.js" /* webpackChunkName: "component---src-templates-game-page-js" */)
}

